import { useState, useEffect } from "react";
import "./styles/newstart.scss";
import Logo from "../images/logo.webp";
import Tick from "../animations/Tick";
import Button from "@mui/material/Button";
import axios from "axios";
import SignatureCanvas from "react-signature-canvas";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom";
import { encryptData } from "../../utils/cryptoUtils";
import Loader from "../animations/Loader";
import Footer from "../Footer";
import moment from "moment";
import imageCompression from "browser-image-compression";

export default function NewStartForm() {
  const [showTick, setShowTick] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [title, setTitle] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [surname, setSurname] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [postCode, setPostCode] = useState("");
  const [homePhone, setHomePhone] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [DOB, setDOB] = useState("");
  const [NIN, setNIN] = useState("");
  const [contractedHours, setContractedHours] = useState("");
  const [EU, setEU] = useState(null);
  const [showBox, setShowBox] = useState(false);
  const [candidateMail, setCandidateMail] = useState("");
  const [confirmMail, setConfirmMail] = useState("");
  const [site, setSite] = useState("");
  const [NOKname, setNOKname] = useState("");
  const [NOKsurname, setNOKsurname] = useState("");
  const [NOKPhone, setNOKPhone] = useState("");
  const [bankName, setBankName] = useState("");
  const [shortCode, setShortCode] = useState("");
  const [bankAcc, setBankAcc] = useState("");
  const [referenceOnce, setReferenceOne] = useState("");
  const [refLastOne, setrefLastOne] = useState("");
  const [refCompanyOne, setRefCompanyOne] = useState("");
  const [refEmail, setRefEmail] = useState("");
  const [ref1type, setRef1type] = useState("");
  const [ref2type, setRef2type] = useState("");
  const [referenceTwo, setReferenceTwo] = useState("");
  const [refLastTwo, setrefLastTwo] = useState("");
  const [refCompanyTwo, setRefCompanyTwo] = useState("");
  const [refEmailTwo, setRefEmailTwo] = useState("");
  const [shareCode, setShareCode] = useState("");
  const [shareExpire, setShareExpire] = useState("");
  const [medical, setMedical] = useState("");
  const [medicalAbility, setMedicalAbility] = useState("");
  const [medicalNotes, setMedicalNotes] = useState("");
  const [conviction, setConviction] = useState("");
  const [convictionNotes, setConvictionNotes] = useState("");
  const [smoke, setSmoke] = useState("");
  const [signature, setSignature] = useState("");
  const [deductions, setDeductions] = useState(false);
  const [alcohol, setAlcohol] = useState(false);
  const [position, setPosition] = useState("");
  const [start, setStart] = useState("");
  const [medBox, setMedBox] = useState(false);
  const [convBox, setConvBox] = useState(false);
  const [employementStatus, setEmploymentStatus] = useState("");
  const [safety, setSafety] = useState(false);
  const [enviromental, setEnviromental] = useState(false);
  const [interviewId, setInterviewId] = useState("");
  const [poa, setPoa] = useState("");
  const [poId, setPoId] = useState("");
  const [country, setCountry] = useState("");
  const [noDetails, setNoDetails] = useState(false);
  const [ip, setIp] = useState("");
  const [availability, setAvailability] = useState("")
  //alert
  const [severity, setSeverity] = useState("");
  const [message, setMessage] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const navigate = useNavigate();
  const url = process.env.REACT_APP_HR_API_URL;
  const today = new Date();

  useEffect(() => {
    let queryParams = new URLSearchParams(window.location.search);
    let id = queryParams.get("id");
    setInterviewId(id);

    //gets applicant details from DB
    axios
      .get(`${url}/onboard/start/${id}`)
      .then((res) => {
        console.log(res.data);
        if (res.data.nspCompleted === true) {
          navigate("/nsp/confirm");
        } else {
          setSite(res.data.Site);
          setContractedHours(res.data.contract);
          setPosition(res.data.position);
          setStart(res.data.start);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 404) {
          setNoDetails(true);
        }
      });

    axios
      .get("https://api.ipify.org/?format=json")
      .then((res) => {
        setIp(res.data.ip);
      })
      .catch((err) => {
        console.log(`Could not get IP Address`);
      });
  }, []);

  const handkleUK = (uk) => {
    if (uk === "Yes") {
      setEU(true);
      setShowBox(false);
    } else if (uk === "No") {
      setEU(false);
      setShowBox(true);
    } else {
      setEU(null);
      setShowBox(false);
    }
  };

  const handleEmployment = (status, id) => {
    if (employementStatus && employementStatus === status) {
      setEmploymentStatus("");
    } else if (employementStatus && employementStatus !== status) {
      document.getElementById("status").style.display = "flex";
      document.getElementById(id).click();
      setTimeout(() => {
        document.getElementById("status").style.display = "none";
      }, 2500);
    } else if (employementStatus === "") {
      setEmploymentStatus(status);
    }
  };

  const handleMedical = (med, id) => {
    if (medical && medical === med) {
      setMedical("");
      setMedBox(false);
    } else if (medical && medical !== med) {
      document.getElementById("medical_alert").style.display = "flex";
      document.getElementById(id).click();
      setTimeout(() => {
        document.getElementById("medical_alert").style.display = "none";
      }, 2500);
    } else if (medical === "" && med === "no") {
      setMedical(med);
    } else if (medical === "" && med !== "no") {
      setMedical(med);
      setMedBox(true);
    }
  };

  const handleConvictions = (conv, id) => {
    if (conviction && conviction === conv) {
      setConviction("");
      setConvBox(false);
    } else if (conviction && conviction !== conv) {
      document.getElementById("convictions").style.display = "flex";
      document.getElementById(id).click();
      setTimeout(() => {
        document.getElementById("convictions").style.display = "none";
      }, 2500);
    } else if (conviction === "" && conv === "yes") {
      setConviction(conv);
      setConvBox(true);
    } else if (conviction === "" && conv === "no") {
      setConviction(conv);
    }
  };

  const handleSmoke = (conf, id) => {
    if (smoke && conf === smoke) {
      setSmoke("");
    } else if (smoke && smoke !== conf) {
      document.getElementById("smoke").style.display = "flex";
      document.getElementById(id).click();
      setTimeout(() => {
        document.getElementById("smoke").style.display = "none";
      }, 2500);
    } else {
      setSmoke(conf);
    }
  };

  const handleDeduction = () => {
    setDeductions(!deductions);
  };

  const handleSafety = () => {
    setSafety(!safety);
  };

  const handleAlcohol = () => {
    setAlcohol(!alcohol);
  };

  const handleEnviromental = () => {
    setEnviromental(!enviromental);
  };

  const handlePoId = async (id) => {
    let extension = id.name.split(".").pop();
    if (extension != "jpg" && extension != "jpeg" && extension != "png") {
      document.getElementById("poi").style.display = "flex";
      setTimeout(() => {
        document.getElementById("poi").style.display = "none";
        document.getElementById("poi_input").value = "";
      }, 1500);
    } else {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 600,
      };
      const compressedFile = await imageCompression(id, options);
      const fileReader = new FileReader();
      fileReader.readAsDataURL(compressedFile);
      fileReader.onload = () => {
        setPoId(fileReader.result);
      };
    }
  };

  const handlePoa = async (img) => {
    let extension = img.name.split(".").pop();
    if (extension != "jpg" && extension != "jpeg" && extension != "png") {
      document.getElementById("poa").style.display = "flex";
      setTimeout(() => {
        document.getElementById("poa").style.display = "none";
        document.getElementById("poa_input").value = "";
      }, 1500);
    } else {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 600,
      };
      const compressedFile = await imageCompression(img, options);
      const fileReader = new FileReader();
      fileReader.readAsDataURL(compressedFile);
      fileReader.onload = () => {
        setPoa(fileReader.result);
      };
    }
  };

  const handleExpireShare = (date) => {
    if (today > new Date(date)) {
      document.getElementById("share").style.display = "flex";
      setTimeout(() => {
        document.getElementById("share").style.display = "none";
        document.getElementById("share_code").value = "";
      }, 1500);
    } else {
      setShareExpire(date);
    }
  };

  const handleDob = (dob) => {
    if (today < new Date(dob)) {
      setMessage("DOB can not be in the future");
      document.getElementById("dob").style.display = "flex";
      setTimeout(() => {
        document.getElementById("dob").style.display = "none";
        document.getElementById("dob_input").value = "";
      }, 1500);
    } else if (moment().diff(moment(dob), "years") < 16) {
      setMessage("You must be at least 16 years old");
      document.getElementById("dob").style.display = "flex";
      setTimeout(() => {
        document.getElementById("dob").style.display = "none";
        document.getElementById("dob_input").value = "";
      }, 1500);
    } else {
      setDOB(dob);
    }
  };

  const handleSubmit = async () => {
    document.getElementById("nsp_btn").style.display = "none";
    let regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let sortcode = shortCode.replaceAll("-", "");

    let med = null;
    if (medical === "no") {
      med = false;
    } else {
      med = true;
    }
    let convicts = null;
    if (conviction === "no") {
      convicts = false;
    } else {
      convicts = true;
    }
    let smk = null;
    if (smoke === "no") {
      smk = false;
    } else {
      smk = true;
    }

    if (
      title === "" ||
      title === "--Please Select One--" ||
      firstName === "" ||
      surname === "" ||
      address === "" ||
      city === "" ||
      // country === "" ||
      postCode === "" ||
      mobilePhone === "" ||
      DOB === "" ||
      NIN === "" ||
      candidateMail === ""
    ) {
      setMessage("Please ensure all your personal details are filled in");
      setSeverity("warning");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("nsp_btn").style.display = "flex";
      }, 3500);
    } else if (EU === null) {
      setMessage("Please select if EU citizen");
      setSeverity("warning");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("nsp_btn").style.display = "flex";
      }, 3500);
    } else if (EU === false && (shareCode === "" || shareExpire === "")) {
      setMessage("Please ensure your visa details are completed");
      setSeverity("warning");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("nsp_btn").style.display = "flex";
      }, 3500);
    } else if (candidateMail != confirmMail) {
      setMessage("Please check your email is right before continuing");
      setSeverity("warning");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("nsp_btn").style.display = "flex";
      }, 3500);
    } else if (!candidateMail.toLowerCase().match(regex)) {
      setMessage("Please check the format of your email before continuing");
      setSeverity("warning");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("nsp_btn").style.display = "flex";
      }, 3500);
    } else if (poa === "" || poId === "") {
      setMessage("Please include images of proof of address and proof of ID");
      setSeverity("warning");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("nsp_btn").style.display = "flex";
      }, 3500);
    } else if (NOKname === "" || NOKPhone === "" || NOKsurname === "") {
      setMessage("Please ensure all your Next Of Kin details are filled in");
      setSeverity("warning");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("nsp_btn").style.display = "flex";
      }, 3500);
    } else if (bankName === "" || sortcode === "" || bankAcc === "") {
      setMessage("Please ensure all your Bank Details are filled in");
      setSeverity("warning");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("nsp_btn").style.display = "flex";
      }, 3500);
    } else if (sortcode.length != 6) {
      setMessage(
        "Your sortcode should be 6 digits long, please double check your sortcode number"
      );
      setSeverity("warning");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("nsp_btn").style.display = "flex";
      }, 3500);
    } else if (bankAcc.length != 8) {
      setMessage(
        "Your bank Account should be 8 digits long, please double check your account number"
      );
      setSeverity("warning");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("nsp_btn").style.display = "flex";
      }, 3500);
    } else if (smoke === "") {
      setMessage("Please read and confirm the smoke policy.");
      setSeverity("warning");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("nsp_btn").style.display = "flex";
      }, 3500);
    } else if (
      referenceOnce === "" ||
      refLastOne === "" ||
      refCompanyOne === "" ||
      refEmail === "" ||
      referenceTwo === "" ||
      refLastTwo === "" ||
      refCompanyTwo === "" ||
      refEmailTwo === "" ||
      ref1type === "" ||
      ref1type === "--Please Select One--" ||
      ref2type === "" ||
      ref2type === "--Please Select One--"
    ) {
      setMessage("Please ensure all your References details are filled in");
      setSeverity("warning");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("nsp_btn").style.display = "flex";
      }, 3500);
    } else if (
      !refEmail.toLowerCase().match(regex) ||
      !refEmailTwo.toLowerCase().match(regex)
    ) {
      setMessage(
        "References emails wrong, please check them before continuing"
      );
      setSeverity("warning");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("nsp_btn").style.display = "flex";
      }, 3500);
    } else if (refEmail.toLowerCase() === refEmailTwo.toLowerCase()) {
      setMessage("References emails should not be the same");
      setSeverity("warning");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("nsp_btn").style.display = "flex";
      }, 3500);
    } else if (ref1type === "Personal" && ref2type === "Personal"){
      setMessage("Both your references cannot be of a personal nature");
      setSeverity("warning");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("nsp_btn").style.display = "flex";
      }, 3500);
    }else if (employementStatus === "") {
      setMessage("Please mark your employment status");
      setSeverity("warning");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("nsp_btn").style.display = "flex";
      }, 3500);
    } else if (med === true && (medicalNotes === "" || medicalAbility === "")) {
      setMessage("Please specify your medical issues");
      setSeverity("warning");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("nsp_btn").style.display = "flex";
      }, 3500);
    } else if (convicts === true && convictionNotes === "") {
      setMessage("Please specify your unspent convictions");
      setSeverity("warning");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("nsp_btn").style.display = "flex";
      }, 3500);
    } else if (!alcohol || !enviromental || !safety || !deductions) {
      setMessage("Please read and agree to all policies.");
      setSeverity("warning");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("nsp_btn").style.display = "flex";
      }, 3500);
    } else if (signature.isEmpty()) {
      setMessage("Please sign the contract");
      setSeverity("warning");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("nsp_btn").style.display = "flex";
      }, 3500);
    } else if (availability == "") {
      setMessage("Please ensure that you have filled in your availability.");
      setSeverity("warning");
      setErrorAlert(true);
      setTimeout(() => {
        setErrorAlert(false);
        setMessage("");
        setSeverity("");
        document.getElementById("nsp_btn").style.display = "flex";
      }, 3500);
    } else {
      setShowLoader(true);
      const signatureData = signature.getTrimmedCanvas().toDataURL("img/png");
      //encription
      let first = encryptData(firstName, process.env.REACT_APP_EMP_KEY);
      let middle = encryptData(middleName, process.env.REACT_APP_EMP_KEY);
      let last = encryptData(surname, process.env.REACT_APP_EMP_KEY);
      let address1 = encryptData(address, process.env.REACT_APP_EMP_KEY);
      let add2 = encryptData(address2, process.env.REACT_APP_EMP_KEY);
      let cit = encryptData(city, process.env.REACT_APP_EMP_KEY);
      let post = encryptData(postCode, process.env.REACT_APP_EMP_KEY);
      let home = encryptData(homePhone.replace(/\s/g, ""), process.env.REACT_APP_EMP_KEY);
      let mobile = encryptData(mobilePhone.replace(/\s/g, ""), process.env.REACT_APP_EMP_KEY);
      let dob = encryptData(DOB, process.env.REACT_APP_EMP_KEY);
      let nin = encryptData(NIN, process.env.REACT_APP_EMP_KEY);
      let mail = encryptData(candidateMail, process.env.REACT_APP_EMP_KEY);
      let ref1Name = encryptData(referenceOnce, process.env.REACT_APP_EMP_KEY);
      let ref1Last = encryptData(refLastOne, process.env.REACT_APP_EMP_KEY);
      let ref1Mail = encryptData(
        refEmail.toLowerCase(),
        process.env.REACT_APP_EMP_KEY
      );
      let ref2Name = encryptData(referenceTwo, process.env.REACT_APP_EMP_KEY);
      let ref2Last = encryptData(refLastTwo, process.env.REACT_APP_EMP_KEY);
      let ref2Mail = encryptData(
        refEmailTwo.toLowerCase(),
        process.env.REACT_APP_EMP_KEY
      );
      let share = encryptData(shareCode, process.env.REACT_APP_EMP_KEY);
      let bank = encryptData(bankName, process.env.REACT_APP_EMP_KEY);
      let short = encryptData(sortcode, process.env.REACT_APP_EMP_KEY);
      let acc = encryptData(bankAcc, process.env.REACT_APP_EMP_KEY);
      let nokName = encryptData(NOKname, process.env.REACT_APP_EMP_KEY);
      let nokLast = encryptData(NOKsurname, process.env.REACT_APP_EMP_KEY);
      let nokPhone = encryptData(NOKPhone, process.env.REACT_APP_EMP_KEY);
      let ability = encryptData(medicalAbility, process.env.REACT_APP_EMP_KEY);
      let meds = encryptData(medicalNotes, process.env.REACT_APP_EMP_KEY);
      let conv = encryptData(convictionNotes, process.env.REACT_APP_EMP_KEY);
      let origin = encryptData(country, process.env.REACT_APP_EMP_KEY);
      let available = encryptData(availability, process.env.REACT_APP_EMP_KEY);

      let empObj = {
        interviewId: interviewId,
        ipAddress: ip,
        title: title,
        firstName: first,
        middleName: middle,
        lastName: last,
        addressLine1: address1,
        addressLine2: add2,
        city: cit,
        country: origin,
        postcode: post,
        homePhone: home,
        mobilePhone: mobile,
        dob: dob,
        nationalInsuranceNumber: nin,
        email: mail,
        startDate: start,
        mainCompanyId: site,
        roleId: position,
        ukCitizen: EU,
        shareCode: share,
        shareExpire: shareExpire,
        poa: poa,
        poi: poId,
        availability: available,
        references: [
          {
            name: `${ref1Name} ${ref1Last}`,
            email: ref1Mail,
            company: refCompanyOne,
            type: ref1type,
          },
          {
            name: `${ref2Name} ${ref2Last}`,
            email: ref2Mail,
            company: refCompanyTwo,
            type: ref2type,
          },
        ],
        contractedHours: contractedHours,
        bankingDetails: {
          bankName: bank,
          sortCode: short,
          accountNumber: acc,
        },
        nextOfKin: {
          firstName: nokName,
          lastName: nokLast,
          phoneNumber: nokPhone,
        },
        additionalDetails: {
          smoker: smk,
          employementStatus: employementStatus,
          medicalAssistance: med,
          ability: ability,
          medicalDescription: meds,
          criminalConvictions: convicts,
          convictionsDescription: conv,
        },
        policy: {
          alcohol: alcohol,
          safety: safety,
          enviromental: enviromental,
          signature: signatureData,
        },
      };

      await axios
        .post(`${url}/employee/add`, empObj)
        .then((res) => {
          console.log(res);
          if (res.status === 200) {
            setShowLoader(false);
            setShowTick(true);
            setTimeout(() => {
              document.getElementById("nsp_btn").style.display = "flex";
              setShowTick(false);
              navigate("/nsp/confirm");
            }, 3500);
          } else if (res.status === 500) {
            setMessage("Something went wrong, please try again");
            setSeverity("warning");
            setErrorAlert(true);
            setTimeout(() => {
              setErrorAlert(false);
              setMessage("");
              setSeverity("");
              document.getElementById("nsp_btn").style.display = "flex";
            }, 3500);
          }
        })
        .catch((err) => {
          console.log(err);
          setShowLoader(false);
          setMessage("Form not submitted, please try again");
          setSeverity("error");
          setErrorAlert(true);
          setTimeout(() => {
            setErrorAlert(false);
            setMessage("");
            setSeverity("");
            document.getElementById("nsp_btn").style.display = "flex";
          }, 3500);
        });
    }
  };

  return (
    <>
      <main className="new_start_form">
        <img src={Logo} alt="logo" />
        {!noDetails ? (
          <>
            <h1>New Start Form</h1>
            <section className="form" id="nsp_form">
              <label for="title">
                Title <span>*</span>
              </label>
              <select onChange={(e) => setTitle(e.target.value)}>
                <option>--Please Select One--</option>
                <option>Ms</option>
                <option>Miss</option>
                <option>Mr</option>
                <option>Mx</option>
              </select>

              <label for="name">
                First Name <span>*</span>
              </label>
              <input
                type="text"
                name="name"
                placeholder="Name"
                required
                className="type"
                id="firstName"
                value={firstName}
                maxLength="75"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
              <label for="name">Middle Name(s)</label>
              <input
                type="text"
                name="name"
                placeholder="Middle name(s)"
                className="type"
                id="middleName"
                value={middleName}
                maxLength="75"
                onChange={(e) => {
                  setMiddleName(e.target.value);
                }}
              />
              <label for="name">
                Last Name <span>*</span>
              </label>
              <input
                type="text"
                name="name"
                placeholder="Last Name"
                required
                className="type"
                id="lastName"
                value={surname}
                maxLength="75"
                onChange={(e) => {
                  setSurname(e.target.value);
                }}
              />
              <label for="name">
                Home Address <span>*</span>
              </label>
              <div className="form_row">
                <input
                  type="text"
                  name="name"
                  placeholder="Address Line 1"
                  required
                  className="type"
                  id="AddressLine1"
                  value={address}
                  maxLength="75"
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                />
                <input
                  type="text"
                  name="name"
                  placeholder="Address Line 2"
                  className="type"
                  id="AddressLine2"
                  value={address2}
                  maxLength="75"
                  onChange={(e) => {
                    setAddress2(e.target.value);
                  }}
                  style={{ marginLeft: "2%" }}
                />
              </div>
              <input
                type="text"
                name="name"
                placeholder="City"
                required
                className="type"
                id="City"
                value={city}
                maxLength="75"
                onChange={(e) => {
                  setCity(e.target.value);
                }}
              />
              <input
                type="text"
                name="name"
                placeholder="Country"
                required
                className="type"
                id="country"
                value={country}
                maxLength="75"
                onChange={(e) => {
                  setCountry(e.target.value);
                }}
              />
              <input
                type="text"
                name="name"
                placeholder="Post Code"
                required
                className="type"
                id="postCode"
                value={postCode}
                maxLength="8"
                onChange={(e) => {
                  setPostCode(e.target.value);
                }}
              />
              <label for="homePhone">Home Phone</label>
              <input
                type="number"
                name="homePhone"
                placeholder="Home Phone"
                className="type"
                id="homePhone"
                value={homePhone.replace(/\s/g, "")}
                maxLength="11"
                onChange={(e) => {
                  setHomePhone(e.target.value.replace(/\s/g, ""));
                }}
              />
              <label for="homePhone">
                Mobile Phone <span>*</span>
              </label>
              <input
                type="number"
                name="mobilePhone"
                placeholder="Mobile Phone"
                className="type"
                id="homePhone"
                maxLength="11"
                value={mobilePhone.replace(/\s/g, "")}
                onChange={(e) => {
                  setMobilePhone(e.target.value.replace(/\s/g, ""));
                }}
              />
              <label for="dateOfBirth">
                Date of Birth <span>*</span>
              </label>
              <p id="dob" className="error_alert">
                {message}
              </p>
              <input
                type="date"
                name="dob"
                className="type"
                id="dob_input"
                value={DOB}
                onChange={(e) => {
                  handleDob(e.target.value);
                }}
              />
              <div className="pageBreak"></div>
              <label for="NIN">
                National Insurance Number <span>*</span>
              </label>
              <input
                type="text"
                name="NIN"
                placeholder="National Insurance Number"
                className="type"
                id="NIN"
                maxLength="9"
                value={NIN}
                onChange={(e) => {
                  setNIN(e.target.value);
                }}
              />
              <label for="EU">
                Are you a UK citizen?
                <span>*</span>
              </label>
              <select
                className="EU"
                onChange={(e) => handkleUK(e.target.value)}
                required
              >
                <option>Are you a UK citizen?</option>
                <option>Yes</option>
                <option>No</option>
              </select>
              {showBox ? (
                <>
                  <label for="shareCode">
                    Share Code <span>*</span>
                  </label>
                  <input
                    type="text"
                    className="type"
                    onChange={(e) => setShareCode(e.target.value)}
                    maxLength="25"
                  />
                  <p id="share" className="error_alert">
                    Visa expiration cannot be in the past
                  </p>

                  <label for="Expire">
                    When does The Visa expire? <span>*</span>
                  </label>
                  <input
                    type="date"
                    className="type"
                    onChange={(e) => handleExpireShare(e.target.value)}
                    id="share_code"
                  />
                </>
              ) : (
                <></>
              )}
              <label for="contractedHours">
                Email <span>*</span>
              </label>
              <input
                type="email"
                name="email"
                placeholder="Enter email"
                className="type"
                id="candidateEmail"
                value={candidateMail}
                onChange={(e) => {
                  setCandidateMail(e.target.value);
                }}
                maxLength="75"
              />
              <input
                type="email"
                name="email"
                placeholder="Confirm email"
                className="type2"
                id="confirmEmail"
                value={confirmMail}
                onChange={(e) => {
                  setConfirmMail(e.target.value);
                }}
                maxLength="75"
              />
              <label>
                Proof of ID <span>*</span>
              </label>
              <p id="poi" className="error_alert">
                You must upload an image
              </p>

              <input
                type="file"
                accepts="image/*"
                onChange={(e) => handlePoId(e.target.files[0])}
                id="poi_input"
              />

              <label>
                Proof of Address <span>*</span>
              </label>
              <p id="poa" className="error_alert">
                You must upload an image
              </p>

              <input
                type="file"
                accepts="image/*"
                id="poa_input"
                onChange={(e) => handlePoa(e.target.files[0])}
              />

              <h4>Next of kin details</h4>
              <hr />
              <label for="NOK">
                Name <span>*</span>
              </label>
              <input
                type="text"
                name="NOK"
                placeholder="First Name"
                className="type"
                id="NOKname"
                maxLength="75"
                value={NOKname}
                onChange={(e) => {
                  setNOKname(e.target.value);
                }}
              />
              <label for="NOK">
                Last Name <span>*</span>
              </label>
              <input
                type="text"
                name="NOK"
                placeholder="Surname"
                className="type"
                id="NOKsurname"
                maxLength="75"
                value={NOKsurname}
                onChange={(e) => {
                  setNOKsurname(e.target.value);
                }}
              />
              <label for="NOK">
                Phone Number <span>*</span>
              </label>
              <input
                type="number"
                name="mobilePhone"
                placeholder="Phone Number"
                className="type"
                id="NOKPhone"
                maxLength="11"
                value={NOKPhone}
                onChange={(e) => {
                  setNOKPhone(e.target.value);
                }}
              />
              <h4>Bank Details</h4>
              <hr />
              <label for="homePhone">
                Bank Name <span>*</span>
              </label>
              <input
                type="text"
                name="mobilePhone"
                placeholder="Bank Name"
                className="type"
                id="homePhone"
                maxLength="75"
                value={bankName}
                onChange={(e) => {
                  setBankName(e.target.value);
                }}
              />
              <label for="NOK">
                Sort Code{" "}
                <small
                  style={{
                    fontSize: ".7rem",
                    color: "#c2c2c2",
                  }}
                >
                  format: 123456
                </small>{" "}
                <span>*</span>
              </label>
              <input
                type="number"
                name="sorCode"
                placeholder="Sort Code"
                className="type"
                id="ShortCode"
                maxLength="6"
                value={shortCode}
                onChange={(e) => {
                  setShortCode(e.target.value);
                }}
              />
              <label for="NOK">
                Account Number{" "}
                <small
                  style={{
                    fontSize: ".7rem",
                    color: "#c2c2c2",
                  }}
                >
                  format: 12345678
                </small>{" "}
                <span>*</span>
              </label>
              <input
                type="number"
                name="shorCode"
                placeholder="Account Number"
                className="type"
                id="bankAcc"
                maxLength="8"
                value={bankAcc}
                onChange={(e) => {
                  setBankAcc(e.target.value);
                }}
              />
              <h4>Are you a Smoker/Vaper?</h4>
              <hr />
              <label for="NOK">
                Do you smoke or vape <span>*</span>
              </label>
              <p id="smoke" className="error_alert">
                Please select only yes or no
              </p>

              <div className="form_row">
                <input
                  type="checkbox"
                  value="no"
                  id="smoke_n"
                  onChange={(e) => handleSmoke(e.target.value, "smoke_n")}
                />
                <label>No</label>
              </div>
              <div className="form_row">
                <input
                  type="checkbox"
                  value="yes"
                  id="smoke_y"
                  onChange={(e) => handleSmoke(e.target.value, "smoke_y")}
                />
                <label>Yes</label>
              </div>

              <p>
                If you are a smoker or vaper, the time you take from shift to do
                so is part of your contracted break.
              </p>
              <p>
                This means if you are contracted to a 20-minute break per shift,
                all breaks that are taken to smoke/vape are part of this
                20-minutes
              </p>
              <p>
                This is monitored by Managers and the Office. If you are found
                to be taking your allowed break and additional breaks for
                smoking/vaping you may be faced with disciplinary action and/or
                deductions from your pay for the extra break time
              </p>
              <h4>Work References</h4>
              <hr />
              <h4>Reference 1</h4>
              <label for="NOK">
                Name <span>*</span>
              </label>
              <div className="form_row">
                <input
                  type="text"
                  name="email"
                  placeholder="First Name"
                  className="type"
                  id="RefName1"
                  value={referenceOnce}
                  onChange={(e) => {
                    setReferenceOne(e.target.value);
                  }}
                  maxLength="75"
                />
                <input
                  type="text"
                  name="email"
                  placeholder="Last Name"
                  className="type2"
                  id="RefLast1"
                  value={refLastOne}
                  onChange={(e) => {
                    setrefLastOne(e.target.value);
                  }}
                  style={{ marginLeft: "2%" }}
                  maxLength="75"
                />
              </div>
              <label for="NOK">
                Company <span>*</span>
              </label>
              <input
                type="text"
                name="shorCode"
                placeholder="Company"
                className="type"
                id="refNumber1"
                maxLength="75"
                value={refCompanyOne}
                onChange={(e) => {
                  setRefCompanyOne(e.target.value);
                }}
              />
              <label for="NOK">
                Email <span>*</span>
              </label>
              <input
                type="email"
                name="shorCode"
                placeholder="Email"
                className="type"
                id="refEmail1"
                value={refEmail}
                onChange={(e) => {
                  setRefEmail(e.target.value);
                }}
                maxLength="75"
              />
              <select onChange={(e) => setRef1type(e.target.value)}>
                <option>--Please Select One--</option>
                <option>Personal</option>
                <option>Professional</option>
              </select>
              <div className="pageBreak"></div>
              <h4>Reference 2</h4>
              <label for="NOK">
                Name <span>*</span>
              </label>
              <div className="form_row">
                <input
                  type="text"
                  name="email"
                  placeholder="First Name"
                  className="type"
                  maxLength="75"
                  id="RefName1"
                  value={referenceTwo}
                  onChange={(e) => {
                    setReferenceTwo(e.target.value);
                  }}
                />
                <input
                  type="text"
                  name="email"
                  placeholder="Last Name"
                  className="type2"
                  maxLength="75"
                  id="RefLast1"
                  value={refLastTwo}
                  onChange={(e) => {
                    setrefLastTwo(e.target.value);
                  }}
                  style={{ marginLeft: "2%" }}
                />
              </div>
              <label for="NOK">
                Company <span>*</span>
              </label>
              <input
                type="text"
                name="shorCode"
                placeholder="Company"
                className="type"
                id="refNumber1"
                maxLength="75"
                value={refCompanyTwo}
                onChange={(e) => {
                  setRefCompanyTwo(e.target.value);
                }}
              />
              <label for="NOK">
                Email <span>*</span>
              </label>
              <input
                type="email"
                name="shorCode"
                placeholder="Email"
                className="type"
                id="refEmail1"
                value={refEmailTwo}
                onChange={(e) => {
                  setRefEmailTwo(e.target.value);
                }}
                maxLength="75"
              />
              <select onChange={(e) => setRef2type(e.target.value)}>
                <option>--Please Select One--</option>
                <option>Personal</option>
                <option>Professional</option>
              </select>
              <h4>Your Availability</h4>
              <hr />
              <label>Please state the times that you would be available to work each day of the week. This may affect what days you are made to work at your store.</label>
              <textarea cols={50} rows={10} onChange={(e)=>setAvailability(e.target.value)}/>
              <h4>Employment Status</h4>
              <hr />
              <label>
                Please read all the following statements carefully and tick the
                one that applies to you. <span>*</span>
              </label>
              <br />
              <p id="status" className="error_alert">
                Please select only one employment status
              </p>
              <div className="form_row">
                <input
                  type="checkbox"
                  value="A"
                  id="A"
                  onChange={(e) => handleEmployment(e.target.value, "A")}
                />
                <label>
                  This job is my first since the 1st of April and I have not
                  been receiving taxable Jobseeker's Allowance or taxable
                  incapacity Benefit or a state/occupational pension.
                </label>
              </div>
              <div className="form_row">
                <input
                  type="checkbox"
                  value="B"
                  id="B"
                  onChange={(e) => handleEmployment(e.target.value, "B")}
                />
                <label>
                  This is my only job, but since the 1st of April I have had
                  another job, or have received taxable Jobseekers Allowance or
                  Incapacity Benefit, I do not receive a state/occupational
                  pension.
                </label>
              </div>
              <div className="form_row">
                <input
                  type="checkbox"
                  value="C"
                  id="C"
                  onChange={(e) => handleEmployment(e.target.value, "C")}
                />
                <label>
                  I have another job or receive a state/occupational pension.
                </label>
              </div>

              <h4>Other</h4>
              <hr />
              <label for="NOK" className="label">
                Please indicate any pre-existing medical or mental health
                conditions and any medication you are taking that may affect
                your ability to carry out your daily duties. <span>*</span>
              </label>
              <p className="error_alert" id="medical_alert">
                Please select only one
              </p>
              <div className="form_row">
                <input
                  type="checkbox"
                  value="no"
                  id="noMed"
                  onChange={(e) => handleMedical(e.target.value, "noMed")}
                />
                <label>No Medical or Mental Health Issues to disclose</label>
              </div>
              <div className="form_row">
                <input
                  type="checkbox"
                  value="med"
                  id="med"
                  onChange={(e) => handleMedical(e.target.value, "med")}
                />
                <label>Yes, I have medical issues to disclose</label>
              </div>
              <div className="form_row">
                <input
                  type="checkbox"
                  value="medMH"
                  id="medMH"
                  onChange={(e) => handleMedical(e.target.value, "medMH")}
                />
                <label>
                  Yes, I have medical & mental health issues to disclose
                </label>
              </div>
              <div className="form_row">
                <input
                  type="checkbox"
                  value="mh"
                  id="mh"
                  onChange={(e) => handleMedical(e.target.value, "mh")}
                />
                <label>Yes, I have mental health issues to disclose</label>
              </div>
              {medBox ? (
                <>
                  <label>
                    Does your pre existing medical condition affect your ability
                    to work <span>*</span>
                  </label>
                  <textarea
                    type="text"
                    cols="40"
                    rows="5"
                    onChange={(e) => setMedicalAbility(e.target.value)}
                  />
                  <label>
                    Please provide the details of your medical and/or mental
                    health issues <span>*</span>
                  </label>
                  <textarea
                    type="text"
                    cols="40"
                    rows="5"
                    onChange={(e) => setMedicalNotes(e.target.value)}
                  />
                </>
              ) : (
                <></>
              )}
              <hr />

              <label for="NOK" className="label">
                You may be required to complete a Basic Disclosure Scotland
                check. Do you have any unspent convictions <span>*</span>
              </label>
              <p id="convictions" className="error_alert">
                Please select only Yes or No
              </p>
              <div className="form_row">
                <input
                  type="checkbox"
                  value="no"
                  id="noConv"
                  onChange={(e) => handleConvictions(e.target.value, "noConv")}
                />
                <label for="no">No</label>
              </div>
              <div className="form_row">
                <input
                  type="checkbox"
                  value="yes"
                  id="yesConv"
                  onChange={(e) => handleConvictions(e.target.value, "yesConv")}
                />
                <label for="yes">Yes</label>
              </div>
              {convBox ? (
                <>
                  <label for="cv">
                    Please give a brief description of any unspent convictions
                  </label>
                  <textarea
                    type="text"
                    name="cv"
                    className="bigText"
                    cols="40"
                    rows="5"
                    onChange={(e) => setConvictionNotes(e.target.value)}
                    max={250}
                  />
                </>
              ) : (
                <></>
              )}
              <div className="pageBreak"></div>
              <h4>Alcohol Policy</h4>
              <hr />
              <p>
                Management Policy Statement on employee and contractor alcohol
                and drug abuse.
              </p>
              <ul>
                <li>
                  The company is committed to a safe, healthy and productive
                  workplace for all employees and contractors.
                </li>
                <li>
                  The company recognises that abuse of alcohol, drugs or other
                  substance by an employee or contractor will impair that
                  person's ability to work properly and safely and could have
                  serious consequences for the safety and efficiency of other
                  employees or contractors. Such behaviour could also damage the
                  company's reputation with the public.
                </li>
                <li>
                  The use, possession, distribution or sale of any illegal or
                  unprescribed drugs or the misuse of legitimately prescribed
                  drugs on company premises is strictly prohibited and is
                  grounds for instant dismissal.
                </li>
                <li>
                  Possession, consumption, distribution or sale of alcoholic
                  beverages, save in the normal course of company trading, is
                  strictly prohibited and is grounds for instant dismissal.
                </li>
                <li>
                  Being unfit for work because of misuse of drugs or alcohol is
                  strictly prohibited and is grounds for instant dismissal.
                </li>
                <li>
                  Whilst the policy above refers specifically to alcohol and
                  drugs, it applies to all forms of substance abuse.
                </li>
                <li>
                  Contractors working on company premises are expected to
                  enforce similar policies regarding alcohol and drug abuse.
                </li>
              </ul>
              <p>
                Contractors working on company premises are expected to enforce
                similar policies regarding alcohol and drug abuse.
              </p>
              <div
                className="form_row form_policy"
                onClick={() => handleAlcohol()}
              >
                <input
                  type="checkbox"
                  id="alcohol-agree"
                  name="Agree"
                  checked={alcohol}
                />
                <label for="Agree">
                  I have read and agree to the Alcohol Policy
                </label>
              </div>
              <h4>Environmental Policy</h4>
              <hr />
              <p>Management Environmental Policy Statement</p>
              <ul>
                <li>
                  The company will ensure that all activities meet legal and EC
                  Directives/requirements and conditions of any applicable
                  Planning Consents.
                </li>
                <li>
                  The company will maintain a safe environment for all
                  employees, customers and contractors.
                </li>
                <li>
                  The company is committed to be a good neighbour and to
                  minimise the impact of any company activities on the
                  neighbourhood.
                </li>
                <li>
                  Wherever reasonably practicable, the company will use
                  environmentally friendly materials, substances and equipment
                  and strive to improve the environmental awareness of all
                  staff.
                </li>
                <li>
                  In the event of any spills of product, leaks or other incident
                  likely to cause any contamination, the company will take all
                  steps to contain and dispose of waste products in an
                  appropriate manner to facilities equipped and licensed to deal
                  with them. All statutory bodies will be notified.
                </li>
              </ul>
              <p>
                All employees/contractors working on company premises are
                expected to confirm to the above policy.
              </p>
              <div
                className="form_row form_policy"
                onClick={() => handleEnviromental()}
              >
                <input
                  type="checkbox"
                  id="environment-agree"
                  name="Agree"
                  checked={enviromental}
                />
                <label for="Agree">
                  I have read and agree to the Environmental Policy
                </label>
              </div>
              <h4>Safety Policy</h4>
              <hr />
              <p>
                Management Safety Policy Statement. - Health & Safety at Work
                etc. Act 1974.
              </p>
              <ul>
                <li>
                  In addition to complying strictly with health and safety
                  measures as required by legislation, it will be the Company's
                  policy to promote, and give high priority to, the continuing
                  establishment of a healthy and safe working environment.
                </li>
                <li>
                  The Company recognises that an effective policy depends on a
                  dedicated attitude by all its employees, whether directly
                  employed or sub-contracted.
                </li>
                <li>
                  The Company's objective is to ensure that for the protection
                  and health, both of Its employees and the general public,
                  working conditions and practices are established and
                  continuously reviewed to achieve a safe, healthy and
                  injury-free operation by means of:
                </li>
                <li>A safe and healthy working environment.</li>
                <li>Appropriate training and employee instruction.</li>
                <li>
                  Identification of potential hazards to health and safety in
                  the workplace and the implementation of plans to eliminate or
                  control such hazards.
                </li>
                <li>
                  Provision of appropriate and adequate protective clothing.
                </li>
                <li>Provision of specialist advice where appropriate.</li>
                <li>
                  Awareness by employees that they themselves have a clear duty
                  to take every reasonable precaution to avoid injury to
                  themselves, their colleagues and members of the public.
                </li>
                <li>
                  The Company will expect that any contractors carrying out work
                  on behalf of the Company will have in place adequate health
                  and safety procedures and standards to safeguard their own
                  employees in addition to the Company's employees and the
                  general public. The Company will take reasonable steps to
                  ensure that such contractors abide by their procedures.
                </li>
              </ul>
              <p>
                Responsibility for implementing and monitoring this policy lies
                with the management.
              </p>
              <div
                className="form_row form_policy"
                onClick={() => handleSafety()}
              >
                <input
                  type="checkbox"
                  id="safety-agree"
                  name="Agree"
                  checked={safety}
                />
                <label for="Agree">
                  {" "}
                  I have read and agree to the Safety Policy
                </label>
              </div>
              <h4>Deductions from Pay Agreement</h4>
              <hr />
              <ul>
                <li>
                  If you are overpaid for any reason, the total amount of the
                  overpayment will normally be deducted from your next payment
                  but if this would cause hardship, arrangements may be made for
                  the overpayment to be recovered over a longer period.
                </li>
                <li>
                  If you are hourly paid and you "clock in" late you will lose
                  pay. If you &ldquo;clock in&rdquo; 5 minutes late you will
                  lose 5 minutes pay and so on.
                </li>
                <li>
                  If you arrive for work more than one hour late without having
                  previously notified us, other arrangements may have been made
                  to cover your duties and you may be sent off the premises for
                  the remainder of the shift without pay.
                </li>
                <li>
                  Lateness or absence may result in disciplinary action and/or
                  loss of appropriate payment.
                </li>
                <li>
                  If there is a temporary shortage of work for any reason, we
                  will try to maintain your continuity of employment even if
                  this necessitates placing you on short time working, or
                  alternatively, lay off. If you are placed on short time
                  working, your pay will be reduced according to time actually
                  worked. If you are placed on lay off, you will receive no pay
                  other than statutory guarantee pay.
                </li>
                <li>
                  If you are an allocated key holder, you must ensure that all
                  procedures and guidelines are followed when securing the
                  building prior to leaving. The keys and any security measure
                  such as alarm codes must be kept safe at all times. You must
                  not give the keys or alarm code to any third party unless
                  authorisation is obtained from a Manager. Any loss or damage
                  caused as a result of your failure to follow procedures or
                  your negligence in ensuring the safekeeping of the keys and
                  alarm code will result in disciplinary action which could lead
                  to your summary dismissal. We also reserve the right to deduct
                  the cost of any loss, repair or replacement from any monies
                  owing to you
                </li>
                <li>
                  Any cash shortages, this includes discrepancies in Paypoint
                  and Lotto, at the end of the shift will be the responsibility
                  of the shift on duty and must be made good by that shift or
                  stock deficiencies that you are found to be responsible for.
                  Any such shortages will be deducted from wages.
                </li>
                <li>Any amounts owing to your personal staff account.</li>
                <li>
                  Any fuel that you have not called down on your till and/or
                  failure to ask a customer if they have any fuel to pay for or
                  any fuel card sale that has been incorrectly cashed. Any such
                  shortages will be deducted from wages.
                </li>
                <li>
                  Any damage to vehicles, stock or property (including
                  non-statutory safety equipment) that is the result of your
                  carelessness, negligence or deliberate vandalism will render
                  you liable to pay the full or part of the cost of repair or
                  replacement.
                  <ul>
                    <li>
                      Any loss to us that is the result of your failure to
                      observe rules, procedures or instruction, or is as a
                      result of your negligent behaviour or your unsatisfactory
                      standards of work will render you liable to reimburse to
                      us the full or part of the cost of the loss.
                    </li>
                    <li>
                      In the event of an at fault accident whilst driving one of
                      our vehicles you may be required to pay the cost of the
                      insurance excess up to a maximum of &pound;250.00.
                    </li>
                    <li>
                      In the event of failure to pay, such costs will be
                      deducted from your pay.
                    </li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>
                  On termination of your employment you must return any Company
                  vehicle in your possession to our premises. Failure to return
                  the vehicle will result in the cost of its recovery being
                  deducted from any monies outstanding to you.
                </li>
                <li>
                  Any fines imposed by relevant authorities including (but not
                  limited to) speeding and parking will be payable by the
                  employee. The Company take no responsibility for the payment
                  of fines incurred by the employee during their employment. In
                  the event that the Company receive the summons on the
                  employee&rsquo;s behalf or owing to a fine incurred by the
                  employee, we may pay the fine and deduct the cost from the
                  employee&rsquo;s wage.
                </li>
                <li>
                  Failure to adequately clean the vehicle may mean you are
                  subject to the cost of the valet being deducted from your pay.
                </li>
                <li>
                  If you arrive for work and, in our opinion, you are not fit to
                  work, we reserve the right to exercise our duty of care if we
                  believe that you may not be able to undertake your duties in a
                  safe manner or may pose a safety risk to others, and send you
                  away for the remainder of the day with or without pay and,
                  dependent on the circumstances, you may be liable to
                  disciplinary action.
                </li>
                <li>Any goods purchased by you from the Employer.</li>
                <li>
                  If you terminate your employment without giving or working the
                  required period of notice, as indicated in your individual
                  statement of main terms of employment, you will have an amount
                  equal to any additional cost of covering your duties during
                  the notice period not worked deducted from any termination pay
                  due to you. You will also forfeit any contractual accrued
                  holiday pay due to you over and above your statutory holiday
                  pay, if you fail to give or work the required period of
                  notice.
                </li>
                <li>
                  On the termination of your employment you must return all our
                  property which is in your possession or for which you have
                  responsibility. Failure to return such items will result in
                  the cost of the items being deducted from any monies
                  outstanding to you.&nbsp;
                </li>
                <li>
                  On the termination of your employment you must return all
                  items of your uniform to us. Failure to return such items will
                  result in the cost of the items being deducted from any monies
                  outstanding to you.
                </li>
              </ul>
              <div
                className="form_row form_policy"
                onClick={() => handleDeduction()}
              >
                <input
                  type="checkbox"
                  id="deduction-agree"
                  name="Agree"
                  checked={deductions}
                />
                <label for="Agree">
                  I have read and agree to the Deductions from Pay Agreement
                </label>
              </div>
            </section>
            <strong style={{ marginTop: "3%" }}>Please sign in the box:</strong>
            <SignatureCanvas
              penColor="black"
              canvasProps={{ className: "sigCanvas" }}
              ref={(ref) => {
                setSignature(ref);
              }}
            />
            <Button
              sx={{ marginBottom: "2%" }}
              color="error"
              onClick={() => signature.clear()}
            >
              Clear Signature
            </Button>
            <Button
              variant="contained"
              color="success"
              id="nsp_btn"
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
            {errorAlert ? <Alert severity={severity}>{message}</Alert> : <></>}
            {showLoader ? <Loader /> : null}
            {showTick ? <Tick /> : <></>}
          </>
        ) : (
          <h2>Can not find any details for this individual.</h2>
        )}
      </main>
      {!noDetails ? <Footer /> : <></>}
    </>
  );
}
