import { useEffect, useState } from "react";
import axios from "axios";
import "./contract.scss";
import SignatureCanvas from "react-signature-canvas";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import party from "party-js";
import Tick from "../animations/Tick";
import { decryptData } from "../../utils/cryptoUtils";
import moment from "moment";
import Footer from "../Footer";
import Logo from "../images/logo.webp";

party.settings.debug = false;
party.resolvableShapes[
    "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
    shapes: [
        "square",
        "rectangle",
        "roundedSquare",
        "circle",
        "roundedRectangle",
        "star",
        // "logo"
    ],
    // The amount of confetti particles that should be emitted.
    count: party.variation.range(80, 200),
    // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
    spread: 30,
    // The initial speed that the confetti particles are emitted with.
    speed: party.variation.range(50, 600),
    // The initial size that the confetti particles are emitted with.
    size: party.variation.skew(1, 0.8),
    // The initial rotation that the confetti particles are emitted with.
    rotation: () => party.random.randomUnitVector().scale(180),
    // The initial color that particles are emitted with.
    color: () =>
        party.random.pick([
            party.Color.fromHex("#238446"),
            party.Color.fromHex("#1D7DB2"),
            party.Color.fromHex("#FFC800"),
            party.Color.fromHex("#FFFFFF"),
        ]),
};

export default function Contract() {
    const [data, setData] = useState({});
    const [signature, setSignature] = useState("");
    const [tick, setTick] = useState(false);
    const navigate = useNavigate();
    const [dbid, setDbid] = useState("");
    const [errorAlert, setErrorAlert] = useState(false);
    const [managerContract, setManagerContract] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("");
    const [breaks, setBreaks] = useState(false);
    const [isWarehouse, setIsWarehouse] = useState(false)
    const [probationDuration, setProbationDuration] = useState("");
    const [noDetails, setNoDetails] = useState(false);
    const [ip, setIp] = useState("");
    const [promotion, setPromotion] = useState(false);
    // const url="http://10.0.0.174:4030"
    const url = process.env.REACT_APP_HR_API_URL;
    document.title = "Onboarding | Contract";

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const userId = queryParams.get("id");
        setDbid(userId);

        if (userId.includes("pr")) {
            setPromotion(true);
        }
        axios
            .get(`${url}/onBoard/new-start/${userId}`)
            .then((res) => {
                console.log(res.data);
                if (
                    res.data.position.toLowerCase().includes("manager") ||
                    res.data.company === "Head Office" ||
                    (res.data.company === "Nitro Solutions 23 Ltd" && res.data.position !== "Warehouse Assistant" && res.data.position !== "Warehouse Packer")
                ) {
                    setManagerContract(true);
                    setBreaks(true);
                }
                if (
                    res.data.position.includes("Supervisor") ||
                    res.data.position.includes("Leader") ||
                    res.data.companyName.includes("RTMS")
                ) {
                    setBreaks(true);
                }

                if (res.data.position == "Warehouse Assistant" || res.data.position == "Warehouse Packer") {
                    setIsWarehouse(true)
                }

                var compAddress = ""

                if (res.data.company === "Head Office" || res.data.company === "Nitro Solutions 23 Ltd") {
                    if (res.data.position.toLowerCase().includes("warehouse") || res.data.position.toLowerCase().includes("operative")) {
                        compAddress = "70 Johnstone Avenue, Hillington, Glasgow, G52 4JT"
                    } else {
                        compAddress = "St James Church, 30 Underwood Road, Paisley, PA3 1TL"
                    }
                } else {
                    compAddress = `${res.data.addressLine1}, ${res.data.city}, ${res.data.postcode}`
                }

                if (res.data._id) {
                    setData(res.data);
                } else {
                    let hourly;
                    if (res.data.salaryHourly) {
                        hourly = res.data.salaryHourly;
                    } else if (res.data.salaryYearly) {
                        hourly = res.data.salaryYearly;
                    }
                    //calculates duration of the probation

                    let probation = moment(res.data.probationDate, "YYYY-MM-DD");
                    let startDate = moment(res.data.startDate, "YYYY-MM-DD");

                    let probationTime = probation.diff(startDate, "months");
                    setProbationDuration(probationTime);
                    let data = {
                        company: res.data.companyName,
                        address: compAddress,
                        firstName: decryptData(
                            res.data.firstName,
                            process.env.REACT_APP_EMP_KEY
                        ),
                        middleName: null,
                        lastName: decryptData(
                            res.data.lastName,
                            process.env.REACT_APP_EMP_KEY
                        ),
                        startDate: new Date(
                            res.data.startDate
                        ).toLocaleDateString("en-GB"),
                        probationDate: new Date(
                            res.data.probationDate
                        ).toLocaleDateString("en-GB"),

                        promotionStart: res.data.promotionStart,

                        position: res.data.position,
                        hourly: hourly,
                        noticePeriod: res.data.noticePeriod,
                        contract: res.data.contractedHours,
                    };

                    if (res.data.middleName != null) {
                        data.middleName = decryptData(
                            res.data.middleName,
                            process.env.REACT_APP_EMP_KEY
                        );
                    }
                    setData(data);
                }
            })
            .catch((err) => {
                console.log(err);
                console.log("err");
                if (err.response.status === 404) {
                    setNoDetails(true);
                }
            });

        axios
            .get("https://api.ipify.org/?format=json")
            .then((res) => {
                setIp(res.data.ip);
            })
            .catch((err) => {
                console.log(`Could not get IP Address`);
            });
    }, []);

    const sign = () => {
        document.getElementById("sign__btn").style.display = "none";
        if (signature.isEmpty()) {
            setMessage("Please sign the contract");
            setSeverity("error");
            setErrorAlert(true);
            setTimeout(() => {
                setErrorAlert(false);
                setMessage("");
                setSeverity("");
                document.getElementById("sign__btn").style.display = "flex";
            }, 3500);
        } else {
            const signatureData = signature
                .getTrimmedCanvas()
                .toDataURL("img/png");
            let details = {
                id: dbid,
                signature: signatureData,
                ipAddress: ip,
            };
            sessionStorage.setItem("site", JSON.stringify(data.company));
            sessionStorage.setItem("staff", JSON.stringify(data.firstName));
            axios
                .post(`${url}/onBoard/new-start/contractReceived`, details)
                .then((res) => {
                    setTick(true);
                    setTimeout(() => {
                        setTick(false);
                        // document.getElementById("sign__btn").style.display =
                        //     "flex";
                        party.confetti(
                            document.getElementById("sign__btn"),
                            confettiSettings
                        );
                        setTimeout(() => {
                            navigate(`/contract-completed`);
                        }, 500);
                    }, 2500);
                })
                .catch((err) => {
                    setMessage("Something went wrong, please try again");
                    setSeverity("error");
                    setErrorAlert(true);
                    setTimeout(() => {
                        setErrorAlert(false);
                        setMessage("");
                        setSeverity("");
                        document.getElementById("sign__btn").style.display =
                            "flex";
                    }, 3500);
                });
        }
    };

    return (
        <>
            <main className="contract">
                {!noDetails ? (
                    <>
                        <h2>STATEMENT OF MAIN TERMS OF EMPLOYMENT</h2>
                        <p className="statement">
                            This Statement, together with the Employee Handbook,
                            forms part of your Contract of Employment (except
                            where the contrary is expressly stated) and sets out
                            particulars of the main terms on which
                        </p>
                        <div className="line"></div>
                        <h1>{data.company}</h1>
                        <h2>{data.address}</h2>
                        <p className="p__tag">Employs:</p>
                        <h2>
                            {data.firstName} {data.middleName} {data.lastName}
                        </h2>
                        <section className="contract">
                            {!promotion ? (
                                <p className="p__tag">
                                    Your employment began on {data.startDate}.
                                    No previous employment counts as part of
                                    your period of continuous employment. You
                                    will serve a probationary period of{" "}
                                    {probationDuration} months that will be
                                    reviewed on {data.probationDate}.
                                </p>
                            ) : (
                                <p className="p__tag">
                                    Your employment began on {data.startDate}.
                                    You were made {data.position} from{" "}
                                    {moment(
                                        data.promotionStart,
                                        "YYYY-MM-DD"
                                    ).format("DD/MM/YYYY")}{" "}
                                    and this counts as part of your previous
                                    continuous employment, subject to
                                    probationary review on:{" "}
                                    {moment(
                                        data.probation,
                                        "YYYY-MM-DD"
                                    ).format("DD/MM/YYYY")}
                                    .
                                </p>
                            )}
                            <h3>job title</h3>
                            <p className="p__tag">
                                You are employed as a {data.position} and your
                                duties will be as advised by your Manager. Your
                                duties may be modified from time to time to suit
                                the needs of the business.
                            </p>
                            <h3>place of work</h3>
                            <p className="p__tag">
                                You will normally be required to work at{" "}
                                {data.company} at {data.address}
                            </p>
                            <p className="p__tag">
                                You may be asked to work at any of our other
                                sites as required by the needs of the business
                                and you will be given notice of no less than 5
                                working days. You will not be required to work
                                outside the United Kingdom.
                            </p>
                            {isWarehouse ?
                                <>
                                    <h3>hours of work</h3>
                                    <p className="p__tag">
                                        Your contracted hours of work are 32 per week. The business is opened 24 hours a day, you will normally be
                                        required to work an 8-hour shift as per the weekly rota, this will include evenings, nights and weekend
                                        working. If you work six hours or more in any one shift you will be entitled to a 20-minute unpaid break. You
                                        may be required to work additional hours when authorised, and as necessitated by the needs of the business.
                                    </p>
                                </>
                                :
                                <>
                                    <h3>job status</h3>
                                    {breaks ? (
                                        <p className="p__tag">
                                            You are employed on an {data.contract} hour
                                            contract. You will normally be required to
                                            work an 8-hour shift as per the weekly rota,
                                            this will include evenings, nights and
                                            weekend working. If you work six hours or
                                            more in any one shift you will be entitled
                                            to a 30-minute unpaid break. You may be
                                            required to work additional hours when
                                            authorised and as necessitated by the needs
                                            of the business.
                                        </p>
                                    ) : (
                                        <p className="p__tag">
                                            You are employed on an {data.contract} hour
                                            contract. You will normally be required to
                                            work an 8-hour shift as per the weekly rota,
                                            this will include evenings, nights and
                                            weekend working. If you work six hours or
                                            more in any one shift you will be entitled
                                            to a 20-minute unpaid break. You may be
                                            required to work additional hours when
                                            authorised and as necessitated by the needs
                                            of the business.
                                        </p>
                                    )}
                                </>
                            }

                            <h3>other employment</h3>
                            <p className="p__tag">
                                If you already have any other employment or are
                                considering any additional employment you must
                                notify us so that we can discuss any
                                implications arising from the current working
                                time legislation.
                            </p>
                            <h3>probationaty period</h3>
                            <p className="p__tag">
                                You join us on an initial probationary period of
                                six months. Your probationary review will be
                                held {data.probationDate}. During this period
                                your work performance and general suitability
                                will be assessed and, if it is satisfactory,
                                your employment will continue. However, if your
                                work performance is not up to the required
                                standard, or you are considered to be generally
                                unsuitable, we may either take remedial action
                                (which may include the extension of your
                                probationary period) or terminate your
                                employment at any time. We reserve the right not
                                to apply our full contractual capability and
                                disciplinary procedures during your probationary
                                period.
                            </p>
                            <h3>employee training</h3>
                            <p className="p__tag">
                                At the commencement of your employment, you will
                                receive training for your specific job, and as
                                your employment progresses your skills may be
                                extended to encompass new job activities within
                                the business. It is a condition of your
                                employment that you participate in any training
                                deemed necessary by us for you to reach the
                                required levels of attainment standards.
                            </p>
                            <h3>remuneration</h3>
                            {managerContract ? (
                                <p className="p__tag">
                                    Your salary is currently {data.hourly} per annum
                                    payable monthly in arrears by BACs
                                    as detailed on your pay statement. In your
                                    first year of employment your salary will be
                                    proportionate to the amount of time left in
                                    the year. This salary is pro-rated from your
                                    contracted hours. Any additional hours
                                    worked will be paid at your normal hourly
                                    rate.
                                </p>
                            ) : (
                                <p className="p__tag">
                                    Your current hourly rate is: {data.hourly}.
                                    Payable monthly in arrears by BACs as
                                    detailed on your pay statement. In your
                                    first year of employment your salary will be
                                    proportionate to the amount of time left in
                                    the year. Any additional hours worked will
                                    be paid at your normal hourly rate.
                                </p>
                            )}
                            <h3>deductions from pay</h3>
                            <p className="p__tag">
                                As a part of our Deductions from Pay Agreement
                                Policy, you will sign a declaration upon
                                commencing employment which outlines your
                                responsibilities and obligations as an employee.
                                Conditions relating to this are shown in the
                                Deductions and Pay Agreement Form found in the
                                New Starter Form to which you should refer.
                            </p>
                            <p className="p__tag">
                                Any uniform provided is company property.
                                Therefore, when commencing employment with us,
                                if you are given any uniform, you will sign a
                                document which states that on termination of
                                your employment, you are required to return your
                                uniform once laundered within 5 days. Failure to
                                do so will result in the cost of these items
                                being deducted from your wages.{" "}
                            </p>
                            <h3>collective agreements</h3>
                            <p className="p__tag">
                                No collective agreements directly affect your
                                terms and conditions of employment.
                            </p>
                            <h3>annual leave and public bank holidays</h3>
                            <p className="p__tag">
                                Your holiday year begins on 1st January and ends
                                on 31st December each year, during which you
                                will receive a paid holiday entitlement of 5.6
                                working weeks inclusive of any public/bank
                                holidays which you may choose to request. In
                                your first holiday year your entitlement will be
                                proportionate to the amount of time left in the
                                holiday year.
                            </p>
                            <p className="p__tag">
                                However, because of the nature of our business
                                you may be required to work on any of the
                                public/bank holidays and it is a condition of
                                employment that you work on these days when
                                required to do so.
                            </p>
                            <p className="p__tag">
                                Where Christmas Day or New Year's Day is worked,
                                payment will be made at double time.
                            </p>
                            <p className="p__tag">
                                Conditions relating to the taking of holidays
                                are shown in the Employee Handbook to which you
                                should refer.
                            </p>
                            <p className="p__tag">
                                In the event of termination of employment
                                holiday entitlement will be calculated as 1/12
                                of the annual entitlement for each completed
                                month of service during that holiday year and
                                any holidays accrued but not taken will be paid
                                for. However, in the event of you having taken
                                any holidays in the current holiday year, which
                                have not been accrued pro-rata, then the
                                appropriate payments will be deducted from your
                                final pay.
                            </p>
                            <h3>sickness pay and conditions</h3>
                            <p className="p__tag">
                                There is no contractual sickness/injury payments
                                scheme in addition to SSP. Conditions relating
                                to this are shown in the Employee Handbook to
                                which you should refer.
                            </p>
                            <h3>maternity/paternity/adoption leave and pay</h3>
                            <p className="p__tag">
                                You may be entitled to
                                maternity/paternity/adoption leave and pay in
                                accordance with the current statutory
                                provisions. Employees are entitled to Statutory
                                Maternity Pay if you have worked for the Company
                                continuously for at least 26 weeks. If you (or
                                your partner) become pregnant or are notified of
                                a match date for adoption purposes you should
                                notify the Manager or the Recruitment/HR Officer
                                at an early stage so that your entitlements and
                                obligations can be explained to you. Employees
                                must tell their employer about the pregnancy at
                                least 15 weeks before the beginning of the week
                                the baby is due.
                            </p>
                            <p className="p__tag">
                                Employees are required to give evidence to the
                                Company of the expected week of confinement,
                                such evidence would normally be in the form MAT
                                B1 and should be provided as soon as possible to
                                the Manager. Employees must inform the Company
                                in writing at least 28 days before the beginning
                                of their expected absence from work due to
                                pregnancy.{" "}
                            </p>
                            <p className="p__tag">
                                Statutory Maternity Pay is payable for a maximum
                                of 39 weeks, and can start from the 11th week
                                before the baby is due, no earlier – but may be
                                later. Employees should inform the Company in
                                writing of when they wish to receive any
                                Statutory Maternity Pay to which they are
                                entitled. Statutory Maternity Pay will be paid
                                in intervals.
                            </p>
                            <p className="p__tag">
                                Employees will be entitled to Statutory
                                Paternity Pay at the rate according to the rules
                                set out in current legislation. Statutory
                                Paternity Pay is payable for 2 weeks, within 8
                                weeks of birth or adoption. Employees must
                                inform the HR Officer 15 weeks before the baby
                                is due by completing SC3 Form, so that we can
                                discuss your entitlements and obligations can be
                                explained to you.
                            </p>
                            <h3>capability and disciplinary procedures</h3>
                            <p className="p__tag">
                                The disciplinary rules that form part of your
                                contract of employment and the procedures that
                                will apply when dealing with capability or
                                disciplinary issues are shown under the headings
                                “Capability Procedures” and “Disciplinary
                                Procedures” in the Employee Handbook to which
                                you should refer.
                            </p>
                            <h3>capability/disciplinary appeal procedure</h3>
                            <p className="p__tag">
                                Should you be dissatisfied with any decision to
                                take action or dismiss you on
                                capability/disciplinary grounds, you should
                                apply, either verbally or in writing, to the
                                Director within five working days of the
                                decision you are complaining against. Further
                                information can be found in the Employee
                                Handbook under the heading
                                “Capability/Disciplinary Appeal Procedure” to
                                which you should refer.
                            </p>
                            <h3>grievance procedure</h3>
                            <p className="p__tag">
                                Should you feel aggrieved at any matter relating
                                to your employment, you should raise the
                                grievance with the Recruitment/HR Officer or the
                                Manager, either verbally or in writing. Further
                                information can be found in the Employee
                                Handbook.
                            </p>
                            <h3>termination of employment</h3>
                            <p className="p__tag">
                                If you terminate your employment without giving
                                or working the required period of notice, as
                                indicated in your individual Statement of Main
                                Terms of Employment, you will have an amount
                                equal to any additional cost of covering your
                                duties during the notice period not worked
                                deducted from any termination pay due to you.
                                This is an express written term of your contract
                                of employment. You will also forfeit any
                                contractual accrued holiday pay due to you over
                                and above your statutory holiday pay, if you
                                fail to give or work the required period of
                                notice.
                            </p>
                            <h3>
                                notice of termination to be given by employer
                            </h3>
                            <p className="p__tag">
                                Under 1 month’s service - Nil.
                            </p>
                            <p className="p__tag">
                                1 month but less than 2 years’ service – 1
                                Weeks.
                            </p>
                            <p className="p__tag">
                                2 years’ service or more - 1 week for each
                                completed year of service to a maximum of 12
                                weeks after 12 years
                            </p>
                            <h3>
                                notice of termination to be given by employee
                            </h3>
                            <p className="p__tag">
                                Under 1 month’s service – Nil.
                            </p>
                            {data.noticePeriod ? (
                                <p className="p__tag">
                                    1 month's service or more -{" "}
                                    {data.noticePeriod}
                                </p>
                            ) : (
                                <p className="p__tag">
                                    1 month’s service or more - 2 Weeks
                                </p>
                            )}<br />
                            <p className="p__tag">
                                We reserve the right not to apply our full
                                contractual capability and disciplinary
                                procedures during your probationary period,
                                which includes notice of termination from the
                                employer.{" "}
                            </p>
                            <h3>pay in lieu of notice</h3>
                            <p className="p__tag">
                                We reserve the contractual right to give pay in
                                lieu of all or any part of the above notice by
                                either party.
                            </p>
                            <h3>pension and pension scheme</h3>
                            <p className="p__tag">
                                When required, we will operate a contributory
                                pension scheme to which you will be
                                auto-enrolled into (subject to the conditions of
                                the scheme). Further details are available from
                                the HR Team.
                            </p><br />
                            <p
                                className="p__tag"
                                style={{ fontWeight: "bold" }}
                            >
                                For and on behalf of the Employer:
                            </p>
                            <div className="signature">
                                <img
                                    src="https://staff.racetrackpitstop.co.uk/wp-content/uploads/2021/10/shamly_sig.png"
                                    alt="Director signature"
                                />
                            </div>
                            <p className="p__tag">
                                I acknowledge receipt of this statement and
                                agree that, for the purpose of the Working Time
                                Regulations, any applicable entitlements and
                                provisions constitute a Relevant Agreement.
                            </p>
                            <div className="signature">
                                <p
                                    className="p__tag"
                                    style={{ fontWeight: "bold" }}
                                >
                                    Employee signature:
                                </p>

                                <SignatureCanvas
                                    penColor="black"
                                    canvasProps={{ className: "sigCanvas" }}
                                    ref={(ref) => {
                                        setSignature(ref);
                                    }}
                                />
                                <Button
                                    sx={{ marginBottom: "2%" }}
                                    color="error"
                                    onClick={() => signature.clear()}
                                >
                                    Clear Signature
                                </Button>
                                {errorAlert ? (
                                    <Alert severity={severity}>{message}</Alert>
                                ) : (
                                    <></>
                                )}

                                <Button
                                    variant="contained"
                                    endIcon={<SendIcon />}
                                    color="error"
                                    className="send__button"
                                    onClick={() => sign()}
                                    id="sign__btn"
                                >
                                    Send
                                </Button>

                                {tick ? <Tick /> : <></>}
                            </div>
                        </section>
                    </>
                ) : (
                    <>
                        <img src={Logo} alt="logo" />
                        <h2>Can not find any details for this individual.</h2>
                    </>
                )}
            </main>
            {!noDetails ? <Footer /> : <></>}
        </>
    );
}
