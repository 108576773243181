import Logo from "../images/logo.webp";
import "./styles/newstart.scss";


export default function Confirmation() {
  return (
    <main className="new_start_form">
      <img src={Logo} alt="logo" />
      <h1>
        Your new start paper work has been sent to HR, please keep an eye on
        your email for your contract coming through.
      </h1>
      <h2>If you need to contact HR you can do on:</h2>
      <a href="tel: 01413198451">0141 319 8451</a>
      <a href="mailto:hr@racetrackpitstop.co.uk">hr@racetrackpitstop.co.uk</a>
    </main>
  );
}
