import { useState, useEffect } from "react";
import axios from "axios";
import logo from "../images/logo.webp";
import "./contract.scss";
import party from "party-js";
import ApartmentIcon from "@mui/icons-material/Apartment";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";

party.settings.debug = false;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 50,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(150, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};

export default function Completed() {
  const [siteData, setSiteData] = useState();
  const [staffName, setStaffName] = useState("");

  useEffect(() => {
    let site = sessionStorage.getItem("site");
    let staff = sessionStorage.getItem("staff");
    setStaffName(JSON.parse(staff));
    party.confetti(document.getElementById("confetti"), confettiSettings);

    axios
      .get(`https://hr-be.racetrackpitstop.co.uk/onBoard/new-start/site/${JSON.parse(site)}`)
      .then((res) => {
        setSiteData(res.data);
        // console.log(res.data);
        let tel = document.getElementById("tel");
        tel.href = `tel:${res.data.Address.phoneNumber}`;
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <main className="congratulations">
      <img src={logo} alt="Logos" />
      <h1 id="confetti">Congratulations!</h1>
      <h2>
        <span>{staffName},</span> welcome to the team
      </h2>
      <div className="progress__container">
        <div className="progress" id="progress"></div>
        <div className="circle active">Interview</div>
        <div className="circle active">Onboarding Form</div>
        <div className="circle active">Contract Signed</div>
        <div className="circle ">First Day</div>
      </div>

      <h2>
        While you wait for your first day, if you need to contact us please take
        note of these numbers:
      </h2>
      <div className="wait">
        <div className="congrat__contact">
          <ApartmentIcon className="congrat__icon" />
          <h3>HR</h3>
          <p>
            Phone Number: <a href="tel:01413198451">0141 319 8451</a>
          </p>
          <p>
            Email:{" "}
            <a href="mailto:hr@racetrackpitstop.co.uk">
              hr@racetrackpitstop.co.uk
            </a>
          </p>
        </div>
        {siteData ? (
          <div className="congrat__contact">
            <StoreMallDirectoryIcon className="congrat__icon" />
            <h3>{siteData.name}</h3>
            <p>Address: {siteData.Address.addressLine1}</p>
            <p>
              Phone Number:{" "}
              <a href="" id="tel">
                {siteData.Address.phoneNumber}
              </a>
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </main>
  );
}
