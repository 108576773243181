import { useEffect, useState } from "react";
import axios from "axios";
import logo from "../images/logo.webp";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Tick from "../animations/Tick";
import "./contract.scss";
import { decryptData } from "../../utils/cryptoUtils";

export default function PreContract() {
  const [data, setData] = useState({});
  const [dbId, setDbId] = useState("");
  const [issue, setIssue] = useState(false);
  const navigate = useNavigate();
  const [problem, setProblem] = useState("");
  const [userName,setUserName] = useState("")
  const [tick, setTick] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  // const url="http://10.0.0.174:4030"
  const url = process.env.REACT_APP_HR_API_URL;
  const [noDetails, setNoDetails] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const userId = queryParams.get("id");
    setDbId(userId);
    let hourly, middleName;
    axios
      .get(`${url}/onBoard/new-start/${userId}`)
      .then((res) => {
        console.log(res.data);

        if (res.data._id) {
          setData(res.data);
        } else {
          
          if (res.data.salaryHourly) {
            hourly = res.data.salaryHourly;
          } else if (res.data.salaryYearly) {
            hourly = res.data.salaryYearly;
          }
          if (res.data.middleName === null){
            middleName = ""
          } else{
            middleName = decryptData(
              res.data.middleName,
              process.env.REACT_APP_EMP_KEY
            )
          }
          setData({
            title: res.data.title,
            firstName: decryptData(
              res.data.firstName,
              process.env.REACT_APP_EMP_KEY
            ),
            middleName: middleName,
            lastName: decryptData(
              res.data.lastName,
              process.env.REACT_APP_EMP_KEY
            ),
            startDate: res.data.startDate,
            contract: res.data.contractedHours,
            signedContract: res.data.signedContract,
            hourly: hourly,
            company: res.data.companyName,
            position: res.data.position,
          });
          setUserName(decryptData(
            res.data.email,
            process.env.REACT_APP_EMP_KEY
          ))
        }
      })
      .catch((err) => {
        console.log(err);
        setNoDetails(true);
      });
  }, []);
  useEffect(() => {
    console.log(data);
  }, [data]);

  const handleSign = () => {
    axios
      .get(`${url}/onBoard/new-start/${dbId}/contractRead`)

      .then((res) => {
        navigate(`/contract?id=${dbId}`);
      })
      .catch((err) => {
        console.log(`could not set contract to read: ${err}`);
      });
  };

  const handleSendHr = () => {
    const data = {
      id: dbId,
      issue: problem,
      email:userName
    };
    console.log(data);
    axios
      .post(`${url}/onBoard/new-start/help`, data)
      .then((res) => {
        // console.log(res.data);
        setIssue(false);
        setTick(true);
        setTimeout(() => {
          setTick(false);
          navigate("/wrong-details");
        }, 3000);
      })
      .catch((err) => {
        setErrorAlert(true);
        setTimeout(() => {
          setErrorAlert(false);
        });
      }, 3000);
  };

  return (
    <main className="precontract">
      <img src={logo} />
      {!noDetails ? (
        <>
          <h1>
            <span>{data.firstName}</span> Welcome to your contract
          </h1>

          {errorAlert ? (
            <Alert severity="error">
              Something went wrong, please try again
            </Alert>
          ) : (
            <></>
          )}
          {data.signedContract === false ? (
            <>
              <h2>
                You are about to sign your contract, please check these details
                before continuing.
              </h2>
              <section className="details">
                <strong>
                  Full Name:{" "}
                  <span>
                    {data.title} {data.firstName} {data.middleName}{" "}
                    {data.lastName}
                  </span>
                </strong>
                <strong>
                  Start Date:{" "}
                  <span>
                    {new Date(data.startDate).toLocaleDateString("en-GB")}
                  </span>
                </strong>
                {data.position.includes("Manager") ||
                data.position === "Area Manager" ||
                data.company === "Head Office"||
                (data.company === "Nitro Solutions 23 Ltd" && data.position!=="Warehouse Assistant" && data.position!== "Warehouse Packer") ? (
                  <>
                    <strong>
                      Contract: <span>{data.contract}</span>
                    </strong>
                    <strong>
                      Salary: <span>{data.hourly}</span>
                    </strong>
                  </>
                ) : (
                  <>
                    <strong>
                      Contract: <span>{data.contract}</span>
                    </strong>
                    <strong>
                      Hourly Rate: <span>{data.hourly}</span>
                    </strong>
                  </>
                )}
              </section>
              <div className="button__row">
                <Button
                  variant="contained"
                  color="success"
                  className="precontract__button"
                  onClick={() => handleSign()}
                >
                 View & Sign
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  className="precontract__button"
                  onClick={() => {
                    setIssue(!issue);
                  }}
                >
                  Wrong Details
                </Button>
              </div>
              {issue ? (
                <div className="precontract__issue">
                  <p>Let us know what is your issue:</p>
                  <textarea
                    cols={40}
                    rows={6}
                    placeholder="Your issue..."
                    onChange={(e) => setProblem(e.target.value)}
                  />
                  <Button
                    variant="outlined"
                    className="hr__button"
                    onClick={() => handleSendHr()}
                  >
                    Send to HR
                  </Button>
                </div>
              ) : (
                <></>
              )}
              {tick ? <Tick /> : <></>}
            </>
          ) : (
            <>
              {/* <Tick /> */}
              <h2
                className="sined_confirmation"
                style={{ textAlign: "center" }}
              >
                Your contract has been signed, if you have not received it in
                the next hour please contact HR on:
                <a href="tel:01413198451" style={{ color: "#bf1a1a" }}>
                  0141 319 8451
                </a>{" "}
                or{" "}
                <a
                  href="mailto:hr@racetrackpitstop.co.uk"
                  style={{ color: "#bf1a1a" }}
                >
                  hr@racetrackpitstop.co.uk
                </a>
              </h2>
            </>
          )}
        </>
      ) : (
        <h2>
          Details have not been found please contact HR on:{" "}
          <a href="tel:01413198451" style={{ color: "#bf1a1a" }}>
            0141 319 8451
          </a>{" "}
          or{" "}
          <a
            href="mailto:hr@racetrackpitstop.co.uk"
            style={{ color: "#bf1a1a" }}
          >
            hr@racetrackpitstop.co.uk
          </a>
        </h2>
      )}
    </main>
  );
}
