import "./styles/notfound.scss";
import logo from "./images/logo.webp";
import tyre from "./images/tyre.jpg";

const NotFound = () => {
    return (
        <section className="not-found">
            <img src={logo} alt="logos" />
            <hr />
            <div className="msg msg-top">Something went wrong!</div>
            <div className="main__error">
                <div className="error__row">
                    <div className="err">4</div>
                    <img src={tyre} alt="tyre" />
                    <div className="err">4</div>
                </div>
            </div>
            <div className="msg msg-bottom">
                Maybe this page moved? Got deleted? Is hiding out in quarantine?
                Never existed in the first place?
            </div>
        </section>
    );
};

export default NotFound;
