import { useState, useEffect } from "react";
import logo from "../images/logo.webp";
import "./contract.scss";

const WrongDetails = () => {
    const [staffName, setStaffName] = useState("");

    useEffect(() => {
        let staff = sessionStorage.getItem("staff");
        setStaffName(JSON.parse(staff));
    }, []);

    return (
        <main className="wrong-details">
            <img src={logo} alt="Logos" />

            <h2>Hi, {staffName}!</h2>
            <h3>
                HR will review your details. Please check your emails for
                further updates.
            </h3>
        </main>
    );
};

export default WrongDetails;
