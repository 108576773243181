import "./styles/home.scss";
import { useState, useEffect } from "react";
import logo from "./images/logo.webp";

const Home = () => {
    const [staffName, setStaffName] = useState("");

    // useEffect(() => {
    //     const staff = sessionStorage.getItem("staff");
    //     setStaffName(JSON.parse(staff));
    // }, []);
    return (
        <main className="home">
            <img src={logo} alt="Logos" />
            <hr/>
            <h1>Hello!</h1>
            <h2>Welcome to the onboarding portal.</h2>
        </main>
    );
};

export default Home;
